import i18next from "i18next";
import { lazy } from "react";

import { getHiddenRoute } from "util/react";

import EN from "./i18n/en.json";

i18next.addResourceBundle("en", "ExportModelSection", EN);

export const URIS = {
  LIST: "/export-model-section",
  EDIT: "/export-model-section/:id",
  NEW: "/export-model-section/new",
};

export const ExportModelSectionListRoute = getHiddenRoute(
  "ExportModelSection",
  "menu.main",
  URIS.LIST,
  lazy(() => import("./layout/ExportModelSectionListLayout")),
  null,
  ["admin"],
);
export const ExportModelSectionCreateRoute = getHiddenRoute(
  "ExportModelSection",
  "menu.main",
  URIS.NEW,
  lazy(() => import("./layout/ExportModelSectionEditLayout")),
  null,
  ["admin"],
);
export const ExportModelSectionEditRoute = getHiddenRoute(
  "ExportModelSection",
  "menu.main",
  URIS.EDIT,
  lazy(() => import("./layout/ExportModelSectionEditLayout")),
  null,
  ["admin"],
);

const ExportModelSectionRoute = (
  <>
    {ExportModelSectionListRoute}
    {ExportModelSectionCreateRoute}
    {ExportModelSectionEditRoute}
  </>
);

export default ExportModelSectionRoute;
